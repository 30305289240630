

































import { Vue, Component } from 'vue-property-decorator';

import SignDocumentsViewModel
  from '@/vue-app/view-models/components/on-boarding/registration-status/sign-documents-view-model';

@Component({ name: 'SignDocuments' })
export default class SignDocuments extends Vue {
  sign_documents_view_model = Vue.observable(new SignDocumentsViewModel());
}
